import { Dialog, pxToRem, useStyles } from '@archipro-design/aria';
import { useState, cloneElement } from 'react';
import { CloseLine } from '@archipro-design/icons';
import Cal from '@calcom/embed-react';
import * as S from './BookingModal.style';

interface BookingModalProps {
    teamBookingSegment: string;
    professionalUrl?: string;
    trigger?: JSX.Element;
    initialOpen?: boolean;
}

const BookingModal = ({
    teamBookingSegment,
    professionalUrl,
    trigger,
    initialOpen,
}: BookingModalProps) => {
    const { css } = useStyles();
    const [open, setOpen] = useState(!!initialOpen);

    const lookingLinkWithMeta = professionalUrl
        ? `${teamBookingSegment}?metadata[professionalUrl]=${professionalUrl}`
        : teamBookingSegment;

    return (
        <>
            {trigger &&
                cloneElement(trigger, {
                    onClick: () => {
                        if (typeof trigger.props.onClick === 'function') {
                            // fire the original onClick if any
                            trigger.props.onClick();
                        }
                        setOpen(true);
                    },
                })}
            <Dialog
                open={open}
                onOpen={() => setOpen(true)}
                onConfirm={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                header={{
                    content: undefined,
                    styles: { display: 'none' },
                }}
                headerAction={{
                    icon: <CloseLine />,
                    title: 'Close',
                    onClick: () => setOpen(false),
                }}
                trapFocus={false}
                content={{
                    content: (
                        <Cal
                            calOrigin={'https://archipro.cal.com'}
                            calLink={`${lookingLinkWithMeta}`}
                            config={{
                                professionalUrl: professionalUrl || '',
                            }}
                        />
                    ),
                    // add a fixed size to avoid iframe flash on browser (safari in 1920x1080)
                    // 100% is a special(default) value and should be a value other than that
                    styles: {
                        width: '98%',
                        margin: '0 auto',
                    },
                }}
                footer={{
                    content: undefined,
                    styles: { display: 'none' },
                }}
                variables={{
                    rootBackground: '#F3F4F6',
                    rootWidth: pxToRem(1038),
                    rootHeight: pxToRem(600),
                    rootPadding: '0',
                    border: '0',
                    contentTopBorderColor: 'transparent',
                }}
                className={css(S.BookingDialog)}
            />
        </>
    );
};

export default BookingModal;
