import type { StyleRule } from '@archipro-design/aria';
import {
    dialogSlotClassNames,
    pxToRem,
    pxArrayToRem,
} from '@archipro-design/aria';
import { svgIconClassName } from '@archipro-design/icons';

export const BookingDialog: StyleRule = () => ({
    [`& .${svgIconClassName}`]: {
        width: `${pxToRem(20)} !important`,
        height: `${pxToRem(20)} !important`,
    },
    [`& .${dialogSlotClassNames.headerAction}`]: {
        padding: pxArrayToRem([10, 14]),
        background: 'transparent',
    },
});
